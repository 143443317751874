.photo-cont {
  max-width: 1000px;
  padding: 20px 0;
  .photo {
    width: 250px;
    margin: 0 auto;
    input, img {
        display: block;
    }
    img {
        width: 250px;
        margin-bottom: 10px;
    }
    span.error {
      color: red;
      text-align: center;
      display: block;
    }
  }
}
