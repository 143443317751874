section.auth {
  > .container {
    max-width: 400px;
    margin: 0 auto;
    box-sizing: border-box;
    padding: 30px 40px;

    h5 {
      text-align: center;
      margin-bottom: 30px;
    }
  }

  header {
    margin: 10px auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background: transparent !important;
    h1 {
      text-align: center;
      font-weight: 500;
      align-items: center;
      font-family: "DM Sans Bold";
      font-size: 17px;
      color: #020e23;
    }
  }
  .flag {
    line-height: 30px;
    text-align: center;
    font-weight: 400;
    font-size: 18px;
    margin-bottom: 10px;
    &.error {
      color: #721c24;
      background-color: #f8d7da;
      border: 1px solid #f5c6cb;
    }
    &.success {
      color: #2d673a;
      background-color: #d5eddb;
      border: 1px solid #c4e6cc;
    }
  }
  background: #fff;
  h3 {
    font-size: 16px;
    font-weight: bold;
    font-family: "DM Sans Regular";
    font-size: 25px;
    margin: 20px 0 10px 0;
    text-align: center;
  }
  .extras {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    a {
      color: #01a85a;
      text-decoration: none;
      font-family: "DM Sans Regular";
      font-size: 17px;
    }
  }
  div.actions {
    margin: 20px 0 10px 0;
    display: flex;
    flex-direction: row;
    justify-content: space-around;

    button {
      text-transform: capitalize;
      font-size: 15px;
      line-height: 35px;
      border: none;
      color: var(--text-light);
      font-weight: 400;
      cursor: pointer;
      padding: 2px 25px;
      text-transform: uppercase;
      border-radius: 5px;
      background-color: #01a85a;
      box-shadow: 0px 10px 30px rgba(0, 80, 133, 0.15);
      span {
        margin-left: 10px;
      }
    }

    button:focus {
      outline: none;
    }
  }
  footer {
    padding: 20px 0;
    margin: 10px auto;
    ul {
      list-style-type: none;
      list-style: none;
      border-bottom: 1px solid #bdc4c9;
      padding: 10px;
      max-width: 500px;
      margin: 0 auto;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-content: center;
      li {
        margin: 0 auto;
        a {
          color: var(--secondary-dark);
          font-size: 14px;
          text-decoration: none;
        }
      }
    }
    p {
      line-height: 40px;
      font-size: 12px;
      text-align: center;
      color: #868686;
    }
  }
}
